const API_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:8000/api' : window.location.origin + '/api';
const HTTP_TIMEOUT = 10000;

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

class server {
    async get_api_call(token,api_url,is_retry = false) {

        var api_url_end = API_URL + api_url;

        try {
            const _res = await fetch(api_url_end, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Authorization': "Bearer " + token
                },
            });
            if (_res?.ok && _res?.status === 200) {
                return _res?.json() 
            } else {
                if(!is_retry && (_res?.status >= 500 || _res?.status === 409)) {
                    this.ingest_log(token,{ severity: 'error', log: `retrying failed ${api_url} status ${_res?.status} get api call`})
                    return wait(1000).then( () => {return this.get_api_call(token, api_url, true)})
                } else {
                    return { success: false, error: _res.status }
                }
            }
        } catch (e) {
            return { success: false, error: e.message };
        }
    }
    async get_standard_api_call( api_url) {

        var api_url_end = API_URL + api_url;

        try {
            let _t = await fetch(api_url_end, {
                method: 'GET',
                cache: 'no-cache',
            });
            return _t.json();
        } catch (e) {
            return { success: false, error: e.message };
        }
    }
    async post_api_call(token,api_url,data_to_send, is_retry = false) {
        var json_to_send = JSON.stringify(data_to_send);
        var api_url_end = API_URL + api_url;

        try {
            const controller = new AbortController();
            const timer = setTimeout(() => controller.abort("TimeoutError"), HTTP_TIMEOUT)
            const _res = await fetch(api_url_end, {
                method: 'POST',
                cache: 'no-cache',
                headers: { 
                    'Content-Type': 'application/json', 
                    'Accept': 'application/json', 
                    'charset': 'utf-8',
                    'Authorization': "Bearer "+token
                },
                signal: controller.signal,
                body: json_to_send,
            })
            if (_res?.ok && _res?.status === 200) {
                clearTimeout(timer)
                return _res?.json() 
            } else {
                clearTimeout(timer)
                if(!is_retry && (_res?.status >= 500 || _res?.status === 409)) {
                    this.ingest_log(token,{ severity: 'warning', log: `retrying failed ${api_url} status ${_res?.status} post api call`})
                    return wait(500).then( () => {return this.post_api_call(token, api_url, data_to_send, true)})
                } else {
                    const j_res = _res?.json()
                    if(j_res)
                        return j_res
                    else 
                        return { success: false, error: _res?.status }
                }
            }
        } catch (e) {
            if(!is_retry && ( e.name === "AbortError" || e.name === "TimeoutError")) {
                await this.ingest_log(token,{ severity: 'warning', log: `retrying failed ${api_url} status ABORTED post api call`})
                return wait(500).then( () => {return this.post_api_call(token, api_url, data_to_send, true)})
            } else {
               return { success: false, error: e.message }; 
            }
        }
    }
    async token_api_call(api_url) {

        var api_url_end = API_URL + api_url;

        try {
            let _t = await fetch(api_url_end, {
                method: 'POST',
                cache: 'no-cache',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'charset': 'utf-8' }
            });
            return _t.json();
        } catch (e) {
            return { success: false, error: e.message };
        }
    }
    
    async get_branding() {
        let api_url = `/get_branding`;
        const response = await this.get_standard_api_call(api_url);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async get_user_token(pin,machine_id) {
        let api_url = `/token?pin=${pin}&machine_id=${machine_id}`;
        const response = await this.token_api_call(api_url);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async get_exam(token) {
        let api_url = `/get_exam`;
        const response = await this.get_api_call(token,api_url);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async exam_security_check(token,security_check_pin) {
        let api_url = `/exam_security_check?security_check_pin=${security_check_pin}`;
        const response = await this.post_api_call(token, api_url, {});
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async start_exam(token) {
        let api_url = `/start_exam`;
        const response = await this.post_api_call(token, api_url, {});
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async update_time(token,consumed_time) {
        let api_url = `/update_consumed_time?consumed_time=${consumed_time}`;
        const response = await this.post_api_call(token, api_url, {});
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async repond_to_question(token, question_number, answer, is_stash_response = false) {
        let api_url = `/respond_to_question?question_number=${question_number}&stash_response=${is_stash_response}`;
        const response = await this.post_api_call(token, api_url, answer);
        if (response == null){ return { success: false, data: null }; } 
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async close_exam(token) {
        let api_url = `/end_exam`;
        const response = await this.post_api_call(token, api_url, {});
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
    async ingest_log(token, logData) {
        let api_url = `/ingest_log`;
        const response = await this.post_api_call(token, api_url, logData);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }
}

export default new server();
