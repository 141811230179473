import React, {useState, useEffect, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import Header from "../../_components/Header/Header";
import ErrorModal from "../../_components/Modals/ErrorModal";
import PaginationControl from "../_exam_component/PaginationControl";
import Question from "../_exam_component/Question";
import { useTranslation } from 'react-i18next';

import "../NavigableExam/NavigableExam.css"
import NavigationDemo from "./NavigationDemo";
import Joyride from "react-joyride";

export default function ExamDemoScreen() {
    
    let navigate = useNavigate();
    let location = useLocation(); 
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const { t, i18n } = useTranslation();
    
    const [isLoaded, setIsLoaded] = useState(false);
    const [examData, setExamData] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })
    const [tourData, setTourData] = useState({
        steps: [
            {
                target: '.questions_nav',
                content: t("navigation_info"),
            },
            {
                target: '.information_image_dot',
                content: t("score_info"),
            },
            {
                target: '.answers_list',
                content: t("answers_info"),
            },
            {
                target: '.exam_question_container',
                content: t("block_info"),
            },
            {
                target: '.end_exam_button',
                content: t("end_exam_info"),
            },
        ],
        run: false,
    })

    //Navigation Handler
    const handleChangeQuestion = (question_index) => {
        setCurrentQuestion(question_index);
    }

    const handleUpdateConsumedTime = (time) => {
        setExamData({
            ...examData,
            consumed_time: time
        })
    }

    const handleSetTimeVariation = (time) => {
        setExamData({
            ...examData,
            time_variation: time
        })
    }

    const handleSetShowCalculator = (show) => {
        setExamData({
            ...examData,
            show_calculator: show
        })
    }

    //Exam Answer Handler
    const handleAnswerQuestion = async (type, question_number, answer_number, selected, block) => {
        answer_number = parseInt(answer_number);
        block = parseInt(block);
        if(type === "base"){
            let qs = examData.questions.slice();
            for (let i in qs) {
                if (i == question_number) {
                    qs[i]["selected_answer_number"] = answer_number;
                    setExamData({
                        ...examData,
                        questions: qs
                    });
                    
                    break;
                }
            }
        } else if (type === "multi") {
            let qs = examData.questions.slice();
            for (let i in qs) {
                if (i == question_number) {
                    if (qs[i]["selected_answer_numbers"] && qs[i]["selected_answer_numbers"].includes(answer_number)){
                        if(!selected){
                            qs[i]["selected_answer_numbers"] = qs[i]["selected_answer_numbers"].filter(el => el !== answer_number);
                        }
                    } else {
                        if (qs[i]["selected_answer_numbers"] === null)
                            qs[i]["selected_answer_numbers"] = [];
                        qs[i]["selected_answer_numbers"].push(answer_number);
                    }
                    setExamData({
                        ...examData,
                        questions: qs
                    });
                    
                    break;
                }
            }
        } else if (type === "block") {
            let qs = examData.questions.slice();
            for (let i in qs) {
                if (i == question_number) {
                    for( let i_b in qs[i].blocks){
                        if(i_b == block)
                            qs[i]["blocks"][i_b]["selected_answer_number"] = answer_number;
                    }
                    setExamData({
                        ...examData,
                        questions: qs
                    });
                    let answers = {};
                    qs[i]["blocks"].forEach((bl) => {
                        if (bl.selected_answer_number !== null && bl.selected_answer_number !== undefined)
                            answers[bl.block_number] = { answer_number: parseInt(bl.selected_answer_number)}
                    })
                    
                    break;
                }
            }
        } 
    }
    const handleAnswerQuestionText = async (question_number, given_answer_text, force_update = false) => {
            let qs = examData.questions.slice();
            for (let i in qs) {
                if (i == question_number) {
                    let words = given_answer_text.split(' ').filter(Boolean);
                    let chars = given_answer_text.length;
                    let previous_chars = qs[i].chars_count ? parseInt(qs[i].chars_count) : 0;
                    //check for words and chars limit
                    if (qs[i].scoring.words_limit) {
                        if (qs[i].scoring.chars_limit) {
                            if (words.length <= qs[i].scoring.words_limit && chars <= qs[i].scoring.chars_limit) {
                                qs[i]["given_answer_text"] = given_answer_text;
                                qs[i]["is_over_limit"] = false;
                            } else {
                                qs[i]["is_over_limit"] = true;
                            }
                        } else if (words.length <= qs[i].scoring.words_limit){
                            qs[i]["given_answer_text"] = given_answer_text;
                            qs[i]["is_over_limit"] = false;
                        } else {
                            qs[i]["is_over_limit"] = true
                        }
                    } else if (qs[i].scoring.chars_limit){
                        if (chars <= qs[i].scoring.chars_limit) {
                            qs[i]["given_answer_text"] = given_answer_text;
                            qs[i]["is_over_limit"] = false;
                        } else {
                            qs[i]["is_over_limit"] = true;
                        }
                    } else {
                        qs[i]["given_answer_text"] = given_answer_text;
                        qs[i]["is_over_limit"] = false;
                    }
                    //save words and chars count
                    qs[i]["chars_count"] = chars;
                    qs[i]["words_count"] = words.length;
                    qs[i]["saved_answer"] = (!qs[i]["is_over_limit"] && (force_update || parseInt(qs[i]["chars_count"]) % 10 === 0))
                    setExamData({
                        ...examData,
                        questions: qs
                    });
                    
                    break;
                }
            }
    }

    const handleCloseErrorModal = () => {
        setErrorModalDetails({
            ...errorModalDetails,
            show: false,
        })
    }

    const checkQuestionTour = (data) => {
        if(data.index === 2 && data.action==="next" && data.status==="running" && data.type==="step:after"){
            setCurrentQuestion(4)
        }
    }

    useEffect(()=> {
        setExamData(location.state.exam_data)
        setIsLoaded(true);
        setTimeout(()=>{setTourData({...tourData, run:true})},5000)
    }, [location.state])

    return (
        <div className="mainExam">
            {!isLoaded ? <div className='loader'></div> : ""}
            {examData ? 
                <>
                    <Header /> 
                    <Joyride
                        run={tourData.run}
                        steps={tourData.steps}
                        scrollToFirstStep={true}
                        continuous={true}
                        showProgress={true}
                        callback={checkQuestionTour}
                        locale={i18n.language === "it-IT" ? { back: 'Indietro', close: 'Chiudi', last: 'Termina', next: 'Prossimo', open: 'Apri', skip: 'Salta' } : null}
                    />
                    <section id="examContent">
                        <div className="row ">
                            <NavigationDemo questions={examData.questions} handleChangeQuestion={handleChangeQuestion} currentQuestion={currentQuestion}  timeout={examData.exam.timeout} consumed_time={examData.consumed_time} time_variation={examData.time_variation} setTimeVariation={handleSetTimeVariation} setConsumedTime={handleUpdateConsumedTime} setShowCalculator={handleSetShowCalculator}/>
                            <Question data={examData.questions[currentQuestion]} show_subjects={examData.exam.show_subjects} handleAnswerQuestion={handleAnswerQuestion} handleAnswerQuestionText={handleAnswerQuestionText}  currentQuestion={currentQuestion} />
                        </div>
                    </section>
                    <PaginationControl currentQuestion={currentQuestion} handleChangeQuestion={handleChangeQuestion} questions={examData.questions} show_calculator={examData.show_calculator}/>
                </>
            : "" }
            <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
        </div>
    )
}