import React, { useEffect } from "react";
import Modal from "react-modal"
import ok_img from "../../assets/icons/success.svg";

export default function SuccessModal(props) {
    const onCloseModal = () => {
        props.handleClose();
    }
    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10010
        },
        content: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: "translate(-50%, -50%)",
            width: '100%',
            maxWidth: "550px",
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: "24px",
            outline: 'none',
            padding: '0'
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="center_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Ok"
                            className="noselect header_image"
                            src={ok_img}
                        />
                    </div>
                    <h2 id="modalTitle" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>{props.title ? props.title : "Ok..."}</h2>
                    <p style={{ textAlign: 'center', marginBottom: "32px" }}>
                        {props.text ? props.text : ""}
                    </p>
                    <button id="closeModalButton" onClick={onCloseModal} className="mx-auto">
                        Chiudi
                    </button>

                </div>
            </Modal>
        </>
    );
}