import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppAuth } from "../../utils/Auth";
import { useNavigate, NavLink } from 'react-router-dom';
import CallServer from '../../api/apis'
import {remoteLog} from '../../utils/logging'

import logo_unisr from '../../assets/icons/unisr_logo.svg'
import logo_tf from "../../assets/icons/logo_menu_blue.svg"
import logo_selexi from "../../assets/icons/logo_selexi.png"

import "../UserHomeScreen/UserHomeScreen.css"
import Header from '../../_components/Header/Header';
import ErrorModal from '../../_components/Modals/ErrorModal';

export default function UserIdentifyScreen({fpPromise}) {

    const { t } = useTranslation();
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const [isLogged, setIsLogged] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userPin, setUserPin] = useState("");
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })


    const GetFromLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key))
    }
    const university_info = GetFromLocalStorage("university_info");

    let navigate = useNavigate();

    const editPin = (event) => {
        setUserPin(event.target.value)
    }

    const handleCloseErrorModal = () => {
        setErrorModalDetails({
            ...errorModalDetails,
            show: false,
        })
    }

    const submitUserPin = async (e) => {
        setIsLoaded(false)
        e.preventDefault();
        const fp = await fpPromise
        const result = await fp.get()
        if(result.visitorId){
            let response = await CallServer.get_user_token(userPin, result.visitorId);
            if (response.success) {
                remoteLog(response.data.access_token,'info','successfully logged in')
                setUserAuthToken(response.data.access_token);
                setIsLogged(true);
            } else {
                setIsLoaded(true);
                if(response.error === "Machine has changed"){
                    setErrorModalDetails({
                        ...errorModalDetails,
                        show: true,
                        title: t('error_title'),
                        text: t('already_logged_error')
                    })
                } else
                    setErrorModalDetails({
                        ...errorModalDetails,
                        show: true,
                        title: t('error_title'),
                        text: t('invalid_pin')
                    })
            }
        }
    }

    useEffect(() => {
        if (userAuthToken)
            navigate("/instructions", { replace: true })
        else
            setTimeout(() => { setIsLogged(false); setIsLoaded(true) }, 2000);
    }, [userAuthToken])

    return (
        <div >
            {!isLoaded ? <div className='loader'></div> : ""}
            <Header />
            <div className="row mt-4 mb-5 align-items-center px-4">
                <div className='col-3'>
                    <img src={university_info ? university_info.image_url : ""} style={{ maxWidth: "200px" }} className="w-100" alt={university_info ? university_info.extended_name : ""} />
                </div>
                <div className='col-6'>
                    <h1 className='text-center text-black'>{t('home_title')}</h1>
                </div>
                <div className='col-3'>
                    <div className='header_pw_by'>
                        <img src={logo_selexi} alt="Selexi SRL" />
                        <img src={logo_tf} alt="SmartCreative SRL" />
                    </div>
                </div>
            </div>
            {!isLogged ?
            <>
                <div className='row mt-3 justify-content-center'>
                    <div className='col-6 p-3' style={{ boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.25)"}}>
                        <form onSubmit={submitUserPin}>
                            <div className='form-group'>
                                <label>PIN</label>
                                <input type="text" value={userPin} className="form-control" onChange={editPin} min={6} required/>
                                <small>{t('pin_input')}</small>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button type='submit' className='mt-2'>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div> 
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className='col-4 p-2 text-center' style={{ boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.25)"}}>
                        <NavLink to="demo/home" className="text-tf">{t("link_to_demo")}</NavLink>
                    </div> 
                </div>
                <div style={{position:"absolute", bottom:0, left:"50%", transform:"translate(-50%,0)"}} className='row mt-5 justify-content-center'>
                    Version {process.env.REACT_APP_VERSION}
                </div>
            </>
            : <p className='text-warning text-center'>{t('loading_exam')}</p>
            }
            <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
        </div>
    )
}