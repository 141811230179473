import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ita_img from '../assets/icons/ita.svg'
import eng_img from '../assets/icons/eng.svg'

import { Dropdown } from "react-bootstrap";


const languageMap = {
    "en-EN": { label: "English", dir: "ltr", active: false, label_img: eng_img },
    "it-IT": { label: "Italiano", dir: "ltr", active: true, label_img: ita_img }
};

export default function LanguageSelect () {
    const selected = localStorage.getItem("i18nextLng") || "it-IT";
    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.dir = languageMap[selected] ? languageMap[selected].dir : languageMap["it-IT"].dir;
        document.documentElement.setAttribute("lang", selected);
    }, [ selected]);

    return (
        <div className="d-flex justify-content-end align-items-center language-select-root">
            
                    {Object.keys(languageMap)?.map(item => (
                        <img src={languageMap[item].label_img} key={item}
                            onClick={() => {
                                i18next.changeLanguage(item);
                            }} 
                            className={selected === item ? "selected" : ""}    
                        />
                    ))}
        </div>
    );
};

