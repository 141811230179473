import React from "react";
import "./Screen.css";
import ScaleText from "react-scale-text";

const Screen = ({ value }) => {
  return (
    <div className="screen">
      <ScaleText >
        {value}
      </ScaleText>
    </div>
  );
};

export default Screen;
