import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import {Spinner} from 'react-bootstrap'
import ErrorModal from "../../_components/Modals/ErrorModal";

import logo_tf from "../../assets/icons/logo_menu_blue.svg"
import logo_selexi from "../../assets/icons/logo_selexi.png"
import calendar_image from "../../assets/icons/calendar.svg"
import clock_image from "../../assets/icons/clock.svg"

import "./UserHomeScreen.css"
import Header from '../../_components/Header/Header';
import { remoteLog } from '../../utils/logging';
import SuccessModal from '../../_components/Modals/SuccessModal';

export default function UserHomeScreen() {

    const { t } = useTranslation();
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    let navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false)
    const [examData, setExamData] = useState(null)
    const [examError, setExamError] = useState("")
    const [securityPin, setSecurityPin] = useState("");
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })
    const [successModalDetails, setSuccessModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })

    const GetFromLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key))
    }
    const university_info = GetFromLocalStorage("university_info");

    const editSecurityPin = (event) => {
        setSecurityPin(event.target.value.toUpperCase())
    }

    const loadUserExam = async () => {
        let response = await CallServer.get_exam(userAuthToken);
        if(response.success){
            setExamData(response.data)
            setIsLoaded(true);
            remoteLog(userAuthToken, 'info', 'successfully loaded exam')
            if(response?.data?.start_date && response?.data?.end_date && response?.data?.next_exam_condition_passed == null && response?.data?.exam?.has_next_exam_condition){
                remoteLog(userAuthToken, 'info', `redirected to waiting correction screen`)
                navigate("/exam/examClosed", { replace: true, state: {exam_data: response?.data, id_interval: 0} });
            }
            if(!response.data.exam.start_date)
                setTimeout(() => {loadUserExam()},15000)
            if(response.data.exam.end_date){
                remoteLog(userAuthToken, 'warning', 'loaded ended exam')
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    title: t('error_title'),
                    text: t('error_exam_ended')
                }) 
            }
        } else {
            setExamError(response.error)
            if(response.error === "Exam ended"){
                remoteLog(userAuthToken, 'warning', 'loaded ended exam')
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    title: t('error_title'),
                    text: t('error_exam_ended')
                }) 
            }
            else{
                remoteLog(userAuthToken, 'error', 'error while loading exam')
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    title: t('error_title'),
                    text: t('error_loading_exam')
                }) 
            }
                
        }
    }

    const startExam = async () => {
        setIsLoaded(false);
        let response = await CallServer.start_exam(userAuthToken);
        if (response.success) {
            if (response.data.exam && response.data.questions){
                remoteLog(userAuthToken, 'info', 'successfully started exam')
                if (response.data.exam.allow_navigation)
                    navigate("/exam/nav", { replace: true, state: { exam_data: response.data } });
                else
                    navigate("/exam/lnr", { replace: true, state: { exam_data: response.data } });
            } else if (response.data.exam && response.data.current_question) {
                remoteLog(userAuthToken, 'info', 'successfully started exam')
                navigate("/exam/lnr", { replace: true, state: { exam_data: response.data } });
            } else {
                remoteLog(userAuthToken, 'error', 'error while starting exam')
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    text: t('error_start_exam')
                })
            }
        } else {
            remoteLog(userAuthToken, 'error', 'error while starting exam '+response.error)
            setErrorModalDetails({
                ...errorModalDetails,
                show: true,
                text: t('error_start_exam')
            })
        }
    }

    const handleUnlockExam = async (e) =>{
        setIsLoaded(false)
        e.preventDefault();
        let response = await CallServer.exam_security_check(userAuthToken,securityPin);
        if (response.success) {
            remoteLog(userAuthToken,'info','successful pass exam security check')
            setSuccessModalDetails({
                ...successModalDetails,
                show: true,
                title: t('security_check_passed_title'),
                text: t('security_check_passed')
            })
            setExamData(response.data)
            setIsLoaded(true)
        } else {
            setIsLoaded(true);
            if(response.error === "Invalid security check pin"){
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    title: t('error_title'),
                    text: t('invalid_security_pin')
                })
            } else if(response.error === "Already passed passed security check"){
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    title: t('error_title'),
                    text: t('security_check_already_passed')
                })
            } else 
            setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    title: t('error_title'),
                    text: t('generic_error')
                })
        }
    }


    const continueExam = async () => {
        setIsLoaded(false)
            if (examData.exam && examData.questions) {
                remoteLog(userAuthToken, 'info', 'successfully continued already started exam')
                if (examData.exam.allow_navigation)
                    navigate("/exam/nav", { replace: true, state: { exam_data: examData} });
                else
                    navigate("/exam/lnr", { replace: true, state: { exam_data: examData } });
            } else if ((examData.exam && examData.current_question) || (examData.exam && examData.start_date && !examData.current_question && !examData.current_question_number)) {
                remoteLog(userAuthToken, 'info', 'successfully continued already started exam')
                navigate("/exam/lnr", { replace: true, state: { exam_data: examData } });
            } else {
                remoteLog(userAuthToken, 'error', 'error while loading already started exam screen')
                setErrorModalDetails({
                    ...errorModalDetails,
                    show: true,
                    text: t('error_start_exam')
                })
            }
    }

    const handleCloseErrorModal = () => {
        setErrorModalDetails({
            ...errorModalDetails,
            show: false,
        })
    }
    const handleCloseSuccessModal = () => {
        setSuccessModalDetails({
            ...successModalDetails,
            show: false,
        })
    }

    useEffect(() => {
        if(userAuthToken)
            loadUserExam();
        else    
            navigate("/", { replace: true })  
    },[userAuthToken])

    return (
         <div id='userHome'> 
            <Header />  
            {!isLoaded ? <div className='loader'></div> : ""}
            <div className="row mt-4 mb-4 align-items-center px-4">
                <div className='col-3'>
                    <img src={university_info ? university_info.image_url : ""} style={{ maxWidth: "200px" }} className="w-100" alt={university_info ? university_info.extended_name : ""} />
                </div>
                <div className='col-6'>
                    <h1 className='text-center text-black'>{t('home_title')}</h1> 
                </div>
                <div className='col-3'>
                    <div className='header_pw_by'>
                        <img src={logo_selexi} alt="Selexi SRL" />
                        <img src={logo_tf} alt="SmartCreative SRL" />
                    </div>
                </div>
            </div>
            {examData ? 
            <div className='examBox mt-3'>
                <div className='row'>
                    <div className='col-9'>
                            
                        <div className='d-flex align-items-center mb-3'>
                            <img src={calendar_image} alt="" />
                            <h5 className='fw-normal ms-2 mb-0 text-capitalize'>{(new Date(examData.exam.date)).toLocaleString((localStorage.getItem("i18nextLng") || "it-IT"), { year: "numeric", weekday: "long", day: "numeric", month: "long", hour: "2-digit", minute: "2-digit" })}</h5>
                        </div>
                        <div className='d-flex align-items-start mb-2'>
                            <div className=''>
                                    <h2>{examData.exam.title}</h2>
                                    <h5>{examData.exam.subtitle}</h5>
                            </div>
                            
                        </div>
                        <div>
                            {examData.firstname || examData.lastname ?
                                <div className=''>
                                        <h5>{t('student_title')}: <span className="text-tf">{examData.firstname} {examData.lastname}</span></h5>
                                </div>
                            : ""}
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                            <img src={clock_image} alt=""  />
                            <p className=" ms-1 mb-0">{parseInt(examData.exam.timeout) + parseInt(examData.time_variation)}min</p>

                        </div>
                    </div>
                    <div className='col-3'>
                        {!examData.end_date && !examData.exam?.end_date ?
                            <>
                                {examData.exam?.start_date && !examData.exam?.end_date ? 
                                    <div>
                                        {examData.start_date ? 
                                            <>
                                                
                                                <button onClick={continueExam} className="w-100">
                                                    {t('continue_exam')}
                                                </button>
                                                <span className='text-secondary'>{t('remaining_time')}: {examData.exam.timeout + examData.time_variation - examData.consumed_time}min</span>
                                                <p className='text-center text-warning mt-2'>{t('continue_available')}</p>
                                            </>
                                        : 
                                        <>
                                            {
                                                !examData.security_check_passed ?
                                                    <>
                                                    <form onSubmit={handleUnlockExam}>
                                                        <div className='form-group'>
                                                            <label>{t('security_pin')}</label>
                                                            <input type="text" value={securityPin} className="form-control" onChange={editSecurityPin} min={6} required/>
                                                            <small>{t('security_pin_input')}</small>
                                                        </div>
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='submit' className='mt-2'>
                                                                {t('unlock_exam')}
                                                            </button>
                                                        </div>
                                                    </form>
                                                    </>
                                                :
                                                <>
                                                    <button onClick={startExam} className="w-100" disabled={!examData.security_check_passed}>
                                                        { t('start_exam')}
                                                    </button>
                                                    <p className='text-center mt-2 text-success' >{t('start_available')}</p>
                                                </>
                                            }
                                            
                                        </>
                                        }
                                    </div>
                                : <div className='text-center'>
                                    <p className='text-warning text-center'> {t('exam_not_started')} </p>
                                    <Spinner animation="border" className='mt-2' />
                                </div>
                                }
                            </>
                        :
                            <>
                                {   examData.end_date ? 
                                    <h5 className='text-danger text-center'>{t('end_exam_title')}</h5>
                                    :
                                    ''
                                }
                            </>
                        }
                    </div>
                </div>
                <div className='row mt-4 mb-5'>
                    <div className='col-12 pt-3' style={{ borderTop: "2px solid rgba(0, 0, 0, 0.3)" }}>
                        {parse(examData?.exam?.instructions)}
                    </div>
                    {
                        examData?.prev_exams?.length > 0 ?
                            <div className='col-12 mt-4 pt-3' style={{ borderTop: "2px solid rgba(0, 0, 0, 0.3)" }}>
                                <p className='text-secondary'>{t('already_done_exam')}:</p>
                                {examData?.prev_exams?.map((exam => {
                                    return (
                                        <div>
                                            <h5>{exam?.exam?.title}</h5>
                                        </div>
                                    )
                                }))}
                            </div>
                        : <></>
                    }
                </div>
            </div>
            : <p className='text-center'>Loading...</p>}
            {examError ? <p className='text-center'>{t('no_exam_available')}</p> : ""}
            <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
            <SuccessModal show={successModalDetails.show} title={successModalDetails.title} text={successModalDetails.text} handleClose={handleCloseSuccessModal}  />
         </div>
     )
 }