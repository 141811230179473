import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import Header from "../../_components/Header/Header";
import ErrorModal from "../../_components/Modals/ErrorModal";
import Question from "../_exam_component/Question";
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { remoteLog } from "../../utils/logging";


import "./LinearExam.css"
import NavigationLinear from "../_exam_component/NavigationLinear";
import PaginationLinear from "../_exam_component/PaginationLinear";
import LinearFinalScreen from "./LinearFinalScreen";
import ConfirmQuestionModal from "../_exam_component/ConfirmQuestionModal";
import { Spinner } from "react-bootstrap";

export default function LinearExam() {

    let navigate = useNavigate();
    let location = useLocation();
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const { t } = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);
    const [examData, setExamData] = useState(null);
    let examData_ref = useRef(examData)

    const [updateTimeout, setUpdateTimeout] = useState(null);
    let updateTimeout_ref = useRef(updateTimeout)

    const [showConfirmQuestionModal, setShowConfirmQuestionModal] = useState(false)
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })
    const [isSendingAnswer, setIsSendingAnswer] = useState(false)

    //Navigation Handler
    const updateStaticExamData = (updatede_data) => {
        setExamData({
            ...examData_ref.current,
            ...updatede_data
        })
    }

    const handleUpdateConsumedTime = (time) => {
        setExamData({
            ...examData_ref.current,
            consumed_time: time
        })
    }

    const handleSetTimeVariation = (time) => {
        setExamData({
            ...examData_ref.current,
            time_variation: time
        })
    }

    const handleSetShowCalculator = (show) => {
        setExamData({
            ...examData_ref.current,
            show_calculator: show
        })
    }

    const handleSetUserInfo = (firstname, lastname) => {
        setExamData({
            ...examData_ref.current,
            firstname: firstname,
            lastname: lastname
        })
    }

    //Exam Answer Handler
    const handleAnswerQuestion = async (type, question_number, answer_number, selected, block) => {
        answer_number = answer_number !== null ? parseInt(answer_number) : null;
        block = parseInt(block);
        if (type === "base") {
            let qs = {...examData.current_question}
            qs["selected_answer_number"] = answer_number;
            setExamData({
                ...examData_ref.current,
                current_question: qs
            });
            remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answer ${answer_number}`)
        } else if (type === "multi") {
            let qs = { ...examData.current_question }
                    if (qs["selected_answer_numbers"] && qs["selected_answer_numbers"].includes(answer_number)) {
                        if (!selected) {
                            qs["selected_answer_numbers"] = qs["selected_answer_numbers"].filter(el => el !== answer_number);
                        }
                    } else {
                        if (qs["selected_answer_numbers"] === null)
                            qs["selected_answer_numbers"] = [];
                        qs["selected_answer_numbers"].push(answer_number);
                    }
            setExamData({
                ...examData_ref.current,
                current_question: qs
            });
            remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answers ${qs["selected_answer_numbers"].join() || ''}`)
        } else if (type === "block") {
            let answers = {}
            let qs = { ...examData_ref.current.current_question }
            for (let i_b in qs.blocks) {
                if (i_b == block)
                    qs["blocks"][i_b]["selected_answer_number"] = answer_number;
                if(qs["blocks"][i_b]["selected_answer_number"] !== null && qs["blocks"][i_b]["selected_answer_number"] !== undefined)
                    answers[i_b] = { answer_number: parseInt(qs["blocks"][i_b]["selected_answer_number"]) }
            }
            setExamData({
                ...examData_ref.current,
                current_question: qs
            });
            remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
        }
    }

    const handleAnswerQuestionText = async (question_number, given_answer_text, force_update = false) => {
        let qs = { ...examData_ref.current.current_question }
                let words = given_answer_text?.split(' ').filter(Boolean) || [];
                let chars = given_answer_text?.length;
                //check for words and chars limit
                if (qs.scoring.words_limit) {
                    if (qs.scoring.chars_limit) {
                        if (words.length <= qs.scoring.words_limit && chars <= qs.scoring.chars_limit) {
                            qs["given_answer_text"] = given_answer_text;
                            qs["is_over_limit"] = false;
                        } else {
                            qs["is_over_limit"] = true;
                        }
                    } else if (words.length <= qs.scoring.words_limit) {
                        qs["given_answer_text"] = given_answer_text;
                        qs["is_over_limit"] = false;
                    } else {
                        qs["is_over_limit"] = true
                    }
                } else if (qs.scoring.chars_limit) {
                    if (chars <= qs.scoring.chars_limit) {
                        qs["given_answer_text"] = given_answer_text;
                        qs["is_over_limit"] = false;
                    } else {
                        qs["is_over_limit"] = true;
                    }
                } else {
                    qs["given_answer_text"] = given_answer_text;
                    qs["is_over_limit"] = false;
                }
                //save words and chars count
                qs["chars_count"] = chars;
                qs["words_count"] = words?.length;
                setExamData({
                    ...examData_ref.current,
                    current_question: qs
                });

                if (!qs["is_over_limit"]){
                    if(updateTimeout_ref.current)
                        clearInterval(updateTimeout_ref.current)
                    setUpdateTimeout(setTimeout( async () =>
                        {
                            remoteLog(userAuthToken, 'info', `automatically saved virtual question ${question_number} text: ${new String(given_answer_text)}`)
                            let response = await CallServer.repond_to_question(userAuthToken, examData.current_question_number, { question_type: "text", answer_text: given_answer_text }, true)
                            if (!response.success){
                                setErrorModalDetails({
                                    ...errorModalDetails,
                                    show: true,
                                    title: t('error_title'),
                                    text: t('error_save_answer')
                                })
                            }
                        }, 1000
                    ))  
                }
    }

    const handleResetBlockQuestion =  (question_number) =>{
        let answers = {}
        let qs = { ...examData_ref.current.current_question }
        for (let i_b in qs.blocks) {
            qs["blocks"][i_b]["selected_answer_number"] = null;
            if(qs["blocks"][i_b]["selected_answer_number"] !== null && qs["blocks"][i_b]["selected_answer_number"] !== undefined)
                answers[i_b] = { answer_number:null }
        }
        setExamData({
            ...examData_ref.current,
            current_question: qs
        });
        remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
    }

    const handleCloseErrorModal = () => {
        setErrorModalDetails({
            ...errorModalDetails,
            show: false,
        })
    }

    const confirmCloseExam = async (id_interval) => {
        if (examData.current_question){
            console.log("Ended Exam")
            await confirmQuestion();
        } 
        let response = await CallServer.close_exam(userAuthToken)
        if (response.success){
            remoteLog(userAuthToken, 'info', `ended exam`)
            navigate("/exam/examClosed", { replace: true, state: {exam_data: examData, id_interval: id_interval} });
        }            
        else
            console.log(response.error)
    }

    const confirmQuestion = async () => {
        setIsSendingAnswer(true)
        if(updateTimeout_ref.current)
            clearInterval(updateTimeout_ref.current)
        let answer_data = null;
        switch(examData.current_question?.question_type){
            case "base": {
                answer_data = {question_type:"base", answer_number:examData.current_question?.selected_answer_number}
            } break;
            case "multi": {
                answer_data = { question_type: "multi", answer_numbers:examData.current_question?.selected_answer_numbers}
            } break;
            case "block": {
                let answer = {}
                examData.current_question["blocks"]?.forEach((bl) => {
                    if (bl.selected_answer_number !== null && bl.selected_answer_number !== undefined )
                        answer[bl.block_number] = { answer_number: parseInt(bl.selected_answer_number) }
                })
                answer_data = { question_type: "block", blocks: answer }
            } break;
            case "text": {
                answer_data = { question_type: "text", answer_text: examData.current_question?.given_answer_text }
                remoteLog(userAuthToken, 'info', `answered virtual question ${examData.current_question_number} text: ${new String(examData.current_question.given_answer_text)}`)
            } break;
        }
        remoteLog(userAuthToken, 'info', `confirmed answer to virtual question ${examData.current_question_number}`)
        let response = await CallServer.repond_to_question(userAuthToken, examData.current_question_number, answer_data)
        if(response.success){
                setExamData({
                    ...examData_ref.current,
                    current_question: response.data.current_question,
                    current_question_number: response.data.current_question_number ? response.data.current_question_number : examData.current_question_number
                })
            setIsSendingAnswer(false)
            setShowConfirmQuestionModal(false)
        } else {
            remoteLog(userAuthToken, 'error', `error while confirming virtual question ${examData.current_question_number}`)
            setErrorModalDetails({
                ...errorModalDetails,
                show: true,
                title: t('error_title'),
                text: t('error_save_answer')
            })
            setIsSendingAnswer(false)
        }
    }

    const handleConfirmQuestion = () => {
        setShowConfirmQuestionModal(true);
    }

    const successHandleConfirmQuestion = useMemo(
        () => debounce(confirmQuestion, 300)
    , [examData, userAuthToken, isSendingAnswer]);

    const handleSecondConfirmationQuestion = () => {
        setShowConfirmQuestionModal(false)
        successHandleConfirmQuestion();
    }

    const handleCloseConfirmQuestionModal = () => {
        setShowConfirmQuestionModal(false)
    }

    useEffect(() => {
        if(userAuthToken && location.state && location.state.exam_data){
            if(location.state.exam_data.current_question?.question_type === "text" && location.state.exam_data.current_question?.stashed_given_answer_text)
                setExamData({
                    ...location.state.exam_data,
                    current_question: {
                        ...location.state.exam_data.current_question,
                        given_answer_text: location.state.exam_data.current_question.stashed_given_answer_text
                    }
                })
            else
                setExamData(location.state.exam_data)
            setIsLoaded(true);
        } else {
            navigate("/", { replace: true});
        }       
    }, [location.state])

    useEffect(() => {
        examData_ref.current = examData
    }, [examData])

    useEffect(() => {
        updateTimeout_ref.current = updateTimeout
    }, [updateTimeout])

    return (
        <div className="mainExam">
            {!isLoaded ? <div className='loader'></div> : ""}
            {isSendingAnswer ? <div className='saving_loader'>
                <div className="saving_tooltip d-flex align-items-center">
                    <Spinner animation="border" className="me-2" style={{maxwidth:"14px"}} /> {t('question_saving')}
                </div>
            </div> : ""}
            {examData ?
                <>
                    <Header hideLanguageSelector={true} examData={examData}  />
                    <section id="examContent">
                        <div className="row ">
                            <NavigationLinear examData={examData} questionsCount={examData.exam.questions_count} currentQuestion={examData.current_question_number} handleCloseExam={confirmCloseExam} timeout={examData.exam.timeout} consumed_time={examData.consumed_time} time_variation={examData.time_variation} setTimeVariation={handleSetTimeVariation} setConsumedTime={handleUpdateConsumedTime} updateStaticExamData={updateStaticExamData} />
                            {examData.current_question ? 
                                <Question data={examData.current_question} show_subjects={examData.exam.show_subjects} handleAnswerQuestion={handleAnswerQuestion} handleAnswerQuestionText={handleAnswerQuestionText} handleResetBlockQuestion={handleResetBlockQuestion} is_linear_exam={true} currentQuestion={examData.current_question_number} />
                            : <LinearFinalScreen handleCloseExam={confirmCloseExam} />}
                            </div>
                    </section>
                    <PaginationLinear examData={examData} isSendingAnswer={isSendingAnswer} currentQuestion={examData.current_question_number} handleConfirmQuestion={handleConfirmQuestion} questionsCount={examData.exam.questions_count} show_calculator={examData.show_calculator} />
                </>
                : ""}
            <ConfirmQuestionModal show={showConfirmQuestionModal} handleClose={handleCloseConfirmQuestionModal} handleConfirmQuestion={handleSecondConfirmationQuestion} />
            <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
        </div>
    )
}