import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../locales/_en.json';
import translationIT from '../locales/_it.json';

const fallbackLng = ['it'];
const availableLanguages = ['it', 'en'];
const resources = {
    it: {
        translation: translationIT
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng, // fallback language is english.
        resources,
        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;