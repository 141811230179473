import CallServer from '../api/apis'

export function remoteLog (token, severity, log) {
  try {
    const logData = {
      severity: severity,
      log: log
    }
    CallServer.ingest_log(token, logData);
    console.log(log)
  } catch(e) {
    console.error(e)
  }
  
}