import React, { useEffect } from "react";
import Modal from "react-modal"
import error_img from "../../assets/icons/Warning.svg";
import { useTranslation } from 'react-i18next';

export default function EndExamModal(props) {

    const { t } = useTranslation();

    const onCloseModal = () => {
        props.handleClose();
    }

    const confirmClose = () => {
        props.handleConfirm();
    }

    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10010
        },
        content: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: "translate(-50%, -50%)",
            width: '100%',
            maxWidth: "550px",
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: "24px",
            outline: 'none',
            padding: '0'
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="center_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Errore"
                            className="noselect header_image"
                            src={error_img}
                        />
                    </div>
                    <h2 id="modalTitle" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>{t("confirm_close_title")}</h2>
                    <div style={{ textAlign: 'center', marginBottom: "32px" }}>
                        {!props.isOnFinalScreen ? <>
                            <p className="text-secondary">{t("have_left")}: {props.remaining.hours}:{props.remaining.minutes}:{props.remaining.seconds}</p>
                            <p className="text-block">{t("confirm_close_text")}</p>
                        </> : <p className="text-secondary">{t("all_answers_saved")}</p>
                        }
                        
                    </div>
                    <div className="d-flex justify-content-center ">
                        <button id="closeModalButton" onClick={onCloseModal} className="white">
                            Annulla
                        </button>
                        <button id="closeModalButton" onClick={confirmClose} className="ms-4">
                            {t("confirm_close")}
                        </button>

                    </div>
                    
                </div>
            </Modal>
        </>
    );
}