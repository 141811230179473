import React, {useState, useEffect, useRef, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import Header from "../../_components/Header/Header";
import ErrorModal from "../../_components/Modals/ErrorModal";
import Navigation from "../_exam_component/Navigation";
import PaginationControl from "../_exam_component/PaginationControl";
import Question from "../_exam_component/Question";
import { useTranslation } from 'react-i18next';

import "./NavigableExam.css"
import { remoteLog } from "../../utils/logging";
import { Spinner } from "react-bootstrap";

export default function NavigableExam() {
    
    let navigate = useNavigate();
    let location = useLocation(); 
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const { t } = useTranslation();
    
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSendingAnswer, setIsSendingAnswer] = useState(false);
    const [examData, setExamData] = useState(null);
    let examData_ref = useRef(examData)

    const [updateTimeout, setUpdateTimeout] = useState(null);
    let updateTimeout_ref = useRef(updateTimeout)

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })


    //Navigation Handler
    const handleChangeQuestion = (question_index) => {
        if(examData_ref.current?.questions[currentQuestion]?.question_type === "text"){
            handleAnswerQuestionText(currentQuestion, examData_ref.current?.questions[currentQuestion]?.given_answer_text, true)
        }
        remoteLog(userAuthToken, 'info', `navigated to virtual question ${question_index}`)
        setCurrentQuestion(question_index);
    }

    const updateStaticExamData = (updatede_data) => {
        setExamData({
            ...examData_ref.current,
            ...updatede_data
        })
    }

    const handleUpdateConsumedTime = (time) => {
        setExamData({
            ...examData_ref.current,
            consumed_time: time
        })
    }

    const handleSetTimeVariation = (time) => {
        setExamData({
            ...examData_ref.current,
            time_variation: time
        })
    }

    //Exam Answer Handler
    const handleAnswerQuestion = async (type, question_number, answer_number, selected, block) => {
        setIsSendingAnswer(true);
        answer_number = answer_number !== null ? parseInt(answer_number) : null;
        block = parseInt(block);
        if(type === "base"){
            let qs = structuredClone(examData.questions)
            for (let i in qs) {
                if (i == question_number) {
                    qs[i]["selected_answer_number"] = answer_number;
                    remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answer ${answer_number}`)
                    let response = await CallServer.repond_to_question(userAuthToken, question_number, { question_type: "base", answer_number:parseInt(answer_number)})
                    if(!response.success){
                        remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} answer ${answer_number}`)
                        setErrorModalDetails({
                            ...errorModalDetails,
                            show: true,
                            title: t('error_title'),
                            text: t('error_save_answer')
                        })
                    } else {
                        setExamData({
                            ...examData,
                            questions: qs
                        });
                    }
                    break;
                }
            }
        } else if (type === "multi") {
            let qs = structuredClone(examData.questions)
            for (let i in qs) {
                if (i == question_number) {
                    if (qs[i]["selected_answer_numbers"] && qs[i]["selected_answer_numbers"].includes(answer_number)){
                        if(!selected){
                            qs[i]["selected_answer_numbers"] = qs[i]["selected_answer_numbers"].filter(el => el !== answer_number);
                        }
                    } else {
                        if (qs[i]["selected_answer_numbers"] === null)
                            qs[i]["selected_answer_numbers"] = [];
                        qs[i]["selected_answer_numbers"].push(answer_number);
                    }
                    remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answers ${qs[i]["selected_answer_numbers"].join() || ''}`)
                    let response = await CallServer.repond_to_question(userAuthToken, question_number, { question_type: "multi", answer_numbers: qs[i]["selected_answer_numbers"] })
                    if(!response.success){
                        remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} answers ${qs[i]["selected_answer_numbers"].join() || ''}`)
                        setErrorModalDetails({
                            ...errorModalDetails,
                            show: true,
                            title: t('error_title'),
                            text: t('error_save_answer')
                        })
                    } else {
                        setExamData({
                            ...examData,
                            questions: qs
                        });
                    }
                    break;
                }
            }
        } else if (type === "block") {
            let qs = structuredClone(examData.questions)
            for (let i in qs) {
                if (i == question_number) {
                    for( let i_b in qs[i].blocks){
                        if(i_b == block)
                            qs[i]["blocks"][i_b]["selected_answer_number"] = answer_number;
                    }
                    let answers = {};
                    qs[i]["blocks"].forEach((bl) => {
                        if (bl.selected_answer_number !== null && bl.selected_answer_number !== undefined)
                            answers[bl.block_number] = { answer_number: parseInt(bl.selected_answer_number)}
                    })
                    remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
                    let response = await CallServer.repond_to_question(userAuthToken, question_number, { question_type: "block", blocks: answers })
                    if(!response.success){
                        remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
                        setErrorModalDetails({
                            ...errorModalDetails,
                            show: true,
                            title: t('error_title'),
                            text: t('error_save_answer')
                        })
                    } else {
                        setExamData({
                            ...examData,
                            questions: qs
                        });
                    }
                    break;
                }
            }
        } 
        setIsSendingAnswer(false);
    }
    const handleAnswerQuestionText = async (question_number, given_answer_text, force_update = false) => {
            let qs = structuredClone(examData.questions)
            for (let i in qs) {
                if (i == question_number) {
                    let words = given_answer_text?.split(' ').filter(Boolean) || [];
                    let chars = given_answer_text?.length;
                    let previous_chars = qs[i].chars_count ? parseInt(qs[i].chars_count) : 0;
                    //check for words and chars limit
                    if (qs[i].scoring.words_limit) {
                        if (qs[i].scoring.chars_limit) {
                            if (words.length <= qs[i].scoring.words_limit && chars <= qs[i].scoring.chars_limit) {
                                qs[i]["given_answer_text"] = given_answer_text;
                                qs[i]["is_over_limit"] = false;
                            } else {
                                qs[i]["is_over_limit"] = true;
                            }
                        } else if (words.length <= qs[i].scoring.words_limit){
                            qs[i]["given_answer_text"] = given_answer_text;
                            qs[i]["is_over_limit"] = false;
                        } else {
                            qs[i]["is_over_limit"] = true
                        }
                    } else if (qs[i].scoring.chars_limit){
                        if (chars <= qs[i].scoring.chars_limit) {
                            qs[i]["given_answer_text"] = given_answer_text;
                            qs[i]["is_over_limit"] = false;
                        } else {
                            qs[i]["is_over_limit"] = true;
                        }
                    } else {
                        qs[i]["given_answer_text"] = given_answer_text;
                        qs[i]["is_over_limit"] = false;
                    }
                    //save words and chars count
                    qs[i]["chars_count"] = chars;
                    qs[i]["words_count"] = words?.length;
                    qs[i]["saved_answer"] = false;
                    setExamData({
                        ...examData,
                        questions: qs
                    });
                    
                    if (!qs[i]["is_over_limit"]){
                        if(updateTimeout_ref.current)
                            clearInterval(updateTimeout_ref.current)
                        if(force_update){
                            remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} text: ${new String(given_answer_text)}`)
                            let response = await CallServer.repond_to_question(userAuthToken, question_number, { question_type: "text", answer_text: given_answer_text })
                            if (!response.success){
                                remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} text: ${new String(given_answer_text)}`)
                                setErrorModalDetails({
                                    ...errorModalDetails,
                                    show: true,
                                    title: t('error_title'),
                                    text: t('error_save_answer')
                                })
                            } else {
                                let qs_current = structuredClone(examData_ref.current.questions)
                                qs_current[i]["saved_answer"] = true
                                setExamData({
                                    ...examData_ref.current,
                                    questions: qs_current
                                });
                            }
                        } else {
                            setUpdateTimeout(setTimeout( async () =>
                                {
                                    remoteLog(userAuthToken, 'info', `automatically saved virtual question ${question_number} text: ${new String(given_answer_text)}`)
                                    let response = await CallServer.repond_to_question(userAuthToken, question_number, { question_type: "text", answer_text: given_answer_text })
                                    if (!response.success){
                                        remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} text: ${new String(given_answer_text)}`)
                                        setErrorModalDetails({
                                            ...errorModalDetails,
                                            show: true,
                                            title: t('error_title'),
                                            text: t('error_save_answer')
                                        })
                                    } else {
                                        let qs_current = structuredClone(examData_ref.current.questions)
                                        qs_current[i]["saved_answer"] = true
                                        setExamData({
                                            ...examData_ref.current,
                                            questions: qs_current
                                        });
                                    }
                                }, 1000
                            ))
                        }
                        
                    }
                    break;
                }
            }
    }

    const handleCloseErrorModal = () => {
        setErrorModalDetails({
            ...errorModalDetails,
            show: false,
        })
    }

    const handleResetBlockQuestion = async (question_number) =>{
        let answers = {}
        let qs = structuredClone(examData.questions)
            for (let i in qs) {
                if (i == question_number) {
                    for( let i_b in qs[i].blocks){
                        qs[i]["blocks"][i_b]["selected_answer_number"] = null;
                        answers[i_b] = { answer_number: null}
                    }
                    remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
                    let response = await CallServer.repond_to_question(userAuthToken, question_number, { question_type: "block", blocks: answers })
                    if(!response.success){
                        remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
                        setErrorModalDetails({
                            ...errorModalDetails,
                            show: true,
                            title: t('error_title'),
                            text: t('error_save_answer')
                        })
                    } else {
                        setExamData({
                            ...examData,
                            questions: qs
                        });
                    }
                    break;
                }
            }
    }

    const handleCloseExam = () => {

    }

    useEffect(()=> {
        if(userAuthToken && location.state && location.state.exam_data){
            setExamData(location.state.exam_data)
            setIsLoaded(true);
        } else {
            navigate("/", { replace: true});
        } 
    }, [location.state])

    useEffect(() => {
        examData_ref.current = examData
    }, [examData])

    useEffect(() => {
        updateTimeout_ref.current = updateTimeout
    }, [updateTimeout])

    return (
        <div className="mainExam">
            {!isLoaded ? <div className='loader'></div> : ""}
            {isSendingAnswer ? <div className='saving_loader'>
                <div className="saving_tooltip d-flex align-items-center">
                    <Spinner animation="border" className="me-2" style={{maxwidth:"14px"}} /> {t('question_saving')}
                </div>
            </div> : ""}
            {examData ? 
                <>
                    <Header hideLanguageSelector={true} examData={examData} />
                    <section id="examContent">
                        <div className="row ">
                            <Navigation examData={examData} questions={examData.questions} handleChangeQuestion={handleChangeQuestion} currentQuestion={currentQuestion} handleCloseExam={handleCloseExam} timeout={examData.exam.timeout} consumed_time={examData.consumed_time} time_variation={examData.time_variation} setTimeVariation={handleSetTimeVariation} setConsumedTime={handleUpdateConsumedTime} updateStaticExamData={updateStaticExamData}  />
                            <Question data={examData.questions[currentQuestion]} show_subjects={examData.exam.show_subjects} handleAnswerQuestion={handleAnswerQuestion} handleAnswerQuestionText={handleAnswerQuestionText} handleResetBlockQuestion={handleResetBlockQuestion}  currentQuestion={currentQuestion} />
                        </div>
                    </section>
                    <PaginationControl currentQuestion={currentQuestion} handleChangeQuestion={handleChangeQuestion} questions={examData.questions} show_calculator={examData.show_calculator}/>
                </>
            : "" }
            <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
        </div>
    )
}