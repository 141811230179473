import React from 'react'
import LanguageSelect from '../LanguageSelect'

import logo_tf from "../../assets/icons/logo_menu_blue.svg"
import "./Header.css"
import { useTranslation } from 'react-i18next';

export default function Header(props) {

    const { t } = useTranslation();

    return (
        <header>
            <div className='container d-flex justify-content-between align-items-center'>
                <div className="pw_label">
                    <span >powered by</span>
                    <img src={logo_tf} alt="thefaculty | SmartCreative SRL" />
                </div>
                { props?.examData?.firstname || props?.examData?.lastname ?
                    <div className='mt-3'>
                        <h5 className="text-secondary">{t('student_title')}: <span className="text-tf">{props?.examData?.firstname} {props?.examData?.lastname}</span></h5>
                    </div>
                : ""}
                {
                    !props.hideLanguageSelector ?
                        <LanguageSelect />  
                    : ""
                }
            </div>
        </header>
    )
}