import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import {Spinner} from 'react-bootstrap'
import ErrorModal from "../../_components/Modals/ErrorModal";

import logo_tf from "../../assets/icons/logo_menu_blue.svg"
import logo_selexi from "../../assets/icons/logo_selexi.png"
import calendar_image from "../../assets/icons/calendar.svg"
import clock_image from "../../assets/icons/clock.svg"

import { FakeExamData } from "./FakeExamData";

import "../UserHomeScreen/UserHomeScreen.css"
import Header from '../../_components/Header/Header';
import Joyride from 'react-joyride';


export default function UserHomeDemoScreen() {

    const { t, i18n } = useTranslation();
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    let navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false)
    const [examData, setExamData] = useState(null)
    const [examError, setExamError] = useState("")
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
    })
    const [tourData, setTourData] = useState({
        steps: [
            {
                target: '.first-step-intro',
                content: t("info_page"),
            },
            {
                target: '.second-step-intro',
                content: t("wait_exam_start"),
            }
        ],
        run: false,
    })

    const GetFromLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key))
    }
    const university_info = GetFromLocalStorage("university_info");

    const loadFakeExam = async () => {
        setExamData(FakeExamData)
        setIsLoaded(true)
        setTourData({...tourData, run:true})
        setTimeout(()=>{setExamData({...FakeExamData, "exam":{...FakeExamData.exam,"start_date":new Date()}})}, 5000)
    }

    const startExam = async () => {
        setIsLoaded(false);
        navigate("/demo/exam", { replace: true, state: { exam_data: examData } });
    }

    const handleCloseErrorModal = () => {
        setErrorModalDetails({
            ...errorModalDetails,
            show: false,
        })
    }


    useEffect(() => {
        setTimeout(()=>{loadFakeExam()},
        2000)
    },[userAuthToken])

    return (
         <div id='userHome'className='first-step-intro'> 
            <Header />  
            <Joyride
                run={tourData.run}
                steps={tourData.steps}
                scrollToFirstStep={true}
                continuous={true}
                showProgress={true}
                locale={i18n.language === "it-IT" ? { back: 'Indietro', close: 'Chiudi', last: 'Termina', next: 'Prossimo', open: 'Apri', skip: 'Salta' } : null}
            />
            {!isLoaded ? <div className='loader'></div> : ""}
            <div className="row mt-4 mb-4 align-items-center px-4">
                <div className='col-3'>
                    <img src={university_info ? university_info.image_url : ""} style={{ maxWidth: "200px" }} className="w-100" alt={university_info ? university_info.extended_name : ""} />
                </div>
                <div className='col-6'>
                    <h1 className='text-center text-black'>{t('home_title')}</h1> 
                </div>
                <div className='col-3'>
                    <div className='header_pw_by'>
                        <img src={logo_selexi} alt="Selexi SRL" />
                        <img src={logo_tf} alt="SmartCreative SRL" />
                    </div>
                </div>
            </div>
            {examData ? 
            <div className='examBox mt-3'>
                <div className='row'>
                    <div className='col-9'>
                            
                        <div className='d-flex align-items-center mb-3'>
                            <img src={calendar_image} alt="" />
                            <h5 className='fw-normal ms-2 mb-0 text-capitalize'>{(new Date(examData.exam.date)).toLocaleString((localStorage.getItem("i18nextLng") || "it-IT"), { year: "numeric", weekday: "long", day: "numeric", month: "long", hour: "2-digit", minute: "2-digit" })}</h5>
                        </div>
                        <div className='d-flex align-items-start mb-2'>
                            <div className=''>
                                    <h2>{examData.exam.title}</h2>
                                    <h5>{examData.exam.subtitle}</h5>
                            </div>
                            
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                            <img src={clock_image} alt=""  />
                            <p className=" ms-1 mb-0">{parseInt(examData.exam.timeout) + parseInt(examData.time_variation)}min</p>

                        </div>
                    </div>
                    <div className='col-3'>
                        {!examData.end_date ?
                        <div className='second-step-intro'>
                        {examData.exam.start_date ? 
                            <div>
                                    <button onClick={startExam} className="w-100">
                                        { t('start_exam')}
                                    </button>
                                    <p className='text-center mt-2 text-success' >{t('start_available')}</p>
                            </div>
                        : <div className='text-center'>
                            <p className='text-warning text-center'> {t('exam_not_started')} </p>
                            <Spinner animation="border" className='mt-2' />
                        </div>
                        }
                        </div>
                        :
                                <h5 className='text-danger text-center'>{t('end_exam_title')}</h5> 
                        }
                    </div>
                </div>
                <div className='row mt-4 mb-5'>
                        <div className='col-12 pt-3' style={{ borderTop: "2px solid rgba(0, 0, 0, 0.3)" }}>
                            {parse(examData.exam.instructions)}
                    </div>
                </div>
            </div>
            : <p className='text-center'>Loading...</p>}
            {examError ? <p className='text-center'>{t('no_exam_available')}</p> : ""}
            <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
         </div>
     )
 }