import "./PaginationControl.css";
import { useTranslation } from 'react-i18next';
import qm_img from "../../assets/icons/question_mark.svg"
import calc_img from "../../assets/icons/calculator.svg"
import Calculator from "../../_components/Calculator/Calculator";
import Draggable from 'react-draggable';
import { useState, useRef } from "react";

export default function PaginationControl(props) {

    const { t } = useTranslation();
    const proceedButton = useRef(null)
    const backButton = useRef(null)

    const changeQuestion = (index) => {
        props.handleChangeQuestion(index);
        proceedButton?.current?.blur();
        backButton?.current?.blur();
    }

    const GetFromLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key))
    }
    const university_info = GetFromLocalStorage("university_info");
    const [showCalculator, setShowCalculator] = useState(false);

    return ( 
        <div className="container exam_pagination_control">
            <div className="row align-items-center">
                <div className="col-3 justify-content-around d-flex">
                    { props.show_calculator ?
                    <div className="d-flex align-items-center justify-content-center pointer" onClick={()=>{setShowCalculator(!showCalculator)}}>
                        <img src={calc_img} alt="Calculator" />
                        <span className="ms-1" style={{ fontSize: "20px", color: "#296D9F", }}>{showCalculator ? t('close_calculator') : t('show_calculator')}</span>
                    </div>
                    : "" }
                    <div className="d-flex align-items-center justify-content-center" >
                        <img src={qm_img} alt="Calculator" />
                        <span className="ms-1" style={{ fontSize: "20px", color: "#296D9F", }}>{t('help')}</span>
                    </div>
                </div>
                <div className="col-9 d-flex align-items-center justify-content-between">
                    <button className="white" ref={backButton} disabled={props.currentQuestion == 0} onClick={() => { changeQuestion(parseInt(props.currentQuestion) - 1) }}>
                        {t('previous')}
                    </button>
                    <span className="text-secondary" style={{ fontSize: "14px" }}>&copy; {new Date().toLocaleString("it-It", { year: "numeric" })} - {university_info ? university_info.extended_name : ""}</span>
                    <button className="white" ref={proceedButton} disabled={props.questions && parseInt(props.currentQuestion) + 1 === props.questions.length} onClick={() => { changeQuestion( parseInt(props.currentQuestion) + 1)}}>
                        {t('following')}
                    </button>
                </div>
            </div>
            {
                props.show_calculator && showCalculator ?
                <div className="calculator_container">
                    <Draggable>
                        <div>
                            <Calculator handleCloseCalculator={()=>{setShowCalculator(false)}}/>
                        </div>
                    </Draggable>
                </div>
                : ""
            }
        </div>
    )
}