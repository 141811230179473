import { useEffect, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { AppAuth } from "../../utils/Auth";
import CallServer from '../../api/apis'
import { remoteLog } from "../../utils/logging";

import logo_unisr from '../../assets/icons/unisr_logo.svg'
import logo_tf from "../../assets/icons/logo_menu_blue.svg"
import logo_selexi from "../../assets/icons/logo_selexi.png"

import "../UserHomeScreen/UserHomeScreen.css"
import Header from '../../_components/Header/Header';
import { Spinner } from 'react-bootstrap';

export default function EndExamScreen() {

    const { t } = useTranslation();
    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const [isLoaded, setIsLoaded] = useState(false);
    const [examData, setExamData] = useState(null);
    let examData_ref = useRef(examData)
    let location = useLocation(); 

    let navigate = useNavigate();

    const GetFromLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key))
    }
    const university_info = GetFromLocalStorage("university_info");

    const handleProceedToNextExam = () => {
        remoteLog(userAuthToken, 'info', 'navigated to next exam waiting screen')
        navigate("/instructions", { replace: true })
    }


    const loadExamData = async () => {
        let response = await CallServer.get_exam(userAuthToken);
        if(response.success){
            console.log(examData_ref.current)
            if ( examData_ref.current?.exam?.has_next_exam_condition && response?.data?.start_date === null){
                setExamData({
                    ...examData_ref.current,
                    next_exam_condition_passed: true
                })
                remoteLog(userAuthToken, 'info', 'updated passed exam condition')  
            } else if ( examData_ref.current?.exam?.has_next_exam_condition && response?.data?.start_date !== null && response?.data?.next_exam_condition_passed === false ){
                setExamData({
                    ...examData_ref.current,
                    next_exam_condition_passed: false,
                    exam: {
                        ...examData_ref.current?.exam,
                        redirect_url: response?.data?.exam?.redirect_url
                    }
                })
                remoteLog(userAuthToken, 'info', 'updated not passed exam condition')   
            } else {
                setTimeout(() => {loadExamData()},15000)
            }    
        } 
    }

    const loadClosingData = async (exam_data) => {
        let response = await CallServer.get_exam(userAuthToken);
        if(response.success){
            if ( response?.data?.exam?.redirect_url !== null){
                setExamData({
                    ...exam_data,
                    exam: {
                        ...examData_ref.current?.exam,
                        redirect_url: response?.data?.exam?.redirect_url
                    }
                })
                remoteLog(userAuthToken, 'info', 'loaded end_exam redirect url')
            } else {
                setExamData(exam_data)
                remoteLog(userAuthToken, 'info', 'loaded end exam screen')
            } 
            setIsLoaded(true)
        } 
    }

    useEffect(() => {
        clearInterval(location?.state?.id_interval)
        //get redirect url if exam has not following and has not condition
        if ( !location?.state?.exam_data?.exam?.has_next && !location?.state?.exam_data?.exam?.has_next_exam_condition){
            loadClosingData(location?.state?.exam_data);  
        } else {
            remoteLog(userAuthToken, 'info', 'loaded end exam screen with next exam')
            setExamData(location?.state?.exam_data)
            setIsLoaded(true)
        }

        //start updating until correction is done
        if ( location?.state?.exam_data?.exam?.has_next_exam_condition && location?.state?.exam_data?.next_exam_condition_passed == null){
            remoteLog(userAuthToken, 'info', 'waiting for update after exam correction')  
            setTimeout(() => {loadExamData()},15000)
            setIsLoaded(true)
        }
            
    }, [location.state])

    useEffect(() => {
        examData_ref.current = examData
    }, [examData])

    return (
        <div >
            <Header />
            {!isLoaded ? <div className='loader'></div> : ""}
            <div className="row mt-4 mb-5 align-items-center px-4">
                <div className='col-3'>
                    <img src={university_info ? university_info.image_url : ""} style={{ maxWidth: "200px" }} className="w-100" alt={university_info ? university_info.extended_name : ""} />
                </div>
                <div className='col-6'>
                    <h1 className='text-center text-black'>{t('home_title')}</h1>
                </div>
                <div className='col-3'>
                    <div className='header_pw_by'>
                        <img src={logo_selexi} alt="Selexi SRL" />
                        <img src={logo_tf} alt="SmartCreative SRL" />
                    </div>
                </div>
            </div>
            <h1 className='text-center text-tf'>{t('end_exam_title')}</h1>
            {
                examData && examData?.exam?.has_next ?
                <>
                    {
                        examData?.exam?.has_next_exam_condition ? 
                        <>
                            { 
                                examData?.next_exam_condition_passed !== null ?
                                <>
                                    { 
                                        examData?.next_exam_condition_passed ?
                                        <>
                                            <p className='text-success text-center'> {t('end_next_exam_condition_passed')} </p>
                                            <div className='d-flex align-items-center mt-3 justify-content-center'>
                                                <button onClick={handleProceedToNextExam}>
                                                    {t("end_exam_proceed_link")}
                                                </button>
                                            </div>
                                        </> :
                                        <>
                                            <p className='text-warning text-center'> {t('end_next_exam_condition_not_passed')} </p>
                                            <div className='d-flex align-items-center mt-3 justify-content-center'>
                                                { 
                                                    examData?.exam?.redirect_url ?
                                                    <a href={examData?.exam?.redirect_url}>
                                                        <button >
                                                            {t("end_exam_proceed_link")}
                                                        </button>
                                                    </a> :
                                                    <a href="/">
                                                        <button>
                                                            {t("back_home")}
                                                        </button>
                                                    </a>
                                                }
                                            </div>
                                        </>
                                    }
                                </> : 
                                <>
                                    <p className='text-center'> {t('end_correction_waiting')} </p>
                                    <div className='d-flex align-items-center mt-3 justify-content-center'>
                                        <Spinner animation="border" variant="warning" className='mt-2 mx-auto' />
                                    </div>
                                </>
                            }
                        </> :
                        <>
                            <p className='text-warning text-center'> {t('end_next_exam_available')} </p>
                            <div className='d-flex align-items-center mt-3 justify-content-center'>
                                <button onClick={handleProceedToNextExam}>
                                    {t("end_exam_proceed_link")}
                                </button>
                            </div>
                        </>
                    }
                </> : 
                <>
                    <p className='text-center'>{t('end_exam_text')}</p>
                    <div className='d-flex justify-content-center mt-4'>
                        { 
                            examData?.exam?.redirect_url ?
                            <a href={examData?.exam?.redirect_url}>
                                <button >
                                    {t("end_exam_proceed_link")}
                                </button>
                            </a> :
                            <a href="/">
                                <button>
                                    {t("back_home")}
                                </button>
                            </a>
                        }
                    </div>
                </>
            }
        </div>
    )
}