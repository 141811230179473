import { useTranslation } from 'react-i18next';

export default function LinearFinalScreen() {

    const { t } = useTranslation();

    return(
        <div className="col-9 exam_question_container px-4 pb-5">
            <div className='mt-5'>
                <h3>{t('confirmed_all_questions')}</h3>
                <p>{t('end_exam_label')}</p>
                
            </div>
        </div>
    )
}