import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate,} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as Sentry from "@sentry/react";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"
import { AppAuth } from "./utils/Auth";

import CallServer from "./api/apis";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import UserIdentifyScreen from "./screens/UserIdentifyScreen/UserIdentifyScreen";
import UserHomeScreen from "./screens/UserHomeScreen/UserHomeScreen";
import ErrorModal from "./_components/Modals/ErrorModal";
import LinearExam from "./screens/LinearExam/LinearExam";
import NavigableExam from "./screens/NavigableExam/NavigableExam";
import EndExamScreen from "./screens/ExamEndScreen/ExamEndScreen";
import UserHomeDemoScreen from "./screens/ExamDemoScreen/UserHomeDemoScreen";
import ExamDemoScreen from "./screens/ExamDemoScreen/ExamDemoScreen";

function App() {

  const [isLogged, setIsLogged] = useState(false);
  const [errorModalDetails, setErrorModalDetails] = useState({
    show: false,
    text: "",
    title: "",
  })

  const [userAuthToken, setUserAuthToken] = useState("");
  const value = { userAuthToken, setUserAuthToken };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { t } = useTranslation();
  const fpPromise = FingerprintJS.load()

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const loadUserToken = async () => {
    const fp = await fpPromise
    const result = await fp.get()
    if(result.visitorId){
        let response = await CallServer.get_user_token(urlParams.get('PIN'), result.visitorId);
        if(response.success) {
          setUserAuthToken(response.data.access_token);
            setIsLogged(true);
        } else {
          if(response.error === "Machine has changed")
            setErrorModalDetails({
              ...errorModalDetails,
              show: true,
              title: t('error_title'),
              text: t('already_logged_error')
            })
          else
            setErrorModalDetails({
              ...errorModalDetails,
              show: true,
              title: t('error_title'),
              text: t('modal_invalid_pin')
            })
        }
    }
  }

  const SaveToLocalStorage = function (key, obj) {
    return localStorage.setItem(key, JSON.stringify(obj))
  }

  const loadUniversityInfo = async () => {
    let response = await CallServer.get_branding();
    if (response.success) {
        SaveToLocalStorage("university_info",response.data);
      document.title = "Portale Esami | "+response.data.name
    } else {
      setErrorModalDetails({
        ...errorModalDetails,
        show: true,
        title: t('error_title'),
        text: "Unable to load University Info"
      })
    }
  }

  const handleCloseErrorModal = () => {
    setErrorModalDetails({
      ...errorModalDetails,
      show: false,
    })
  }

  useEffect(() => {
    if(urlParams.get('PIN'))
      loadUserToken()
    loadUniversityInfo()
  },[])

  return (
    <AppAuth.Provider value={value}>
      <div className="App">
        <BrowserRouter >
        <div id='mainContent' className="container ">
          <SentryRoutes>
                <Route index path="/" element={<UserIdentifyScreen fpPromise={fpPromise}/>} />
                <Route path="/instructions" element={<UserHomeScreen />} />
                <Route path="/exam/" >
                  <Route path="lnr" element={<LinearExam />} />
                  <Route path="nav" element={<NavigableExam />} />
                  <Route path="examClosed" element={<EndExamScreen />} />
                </Route>
                <Route path="/demo/" >
                  <Route path="home" element={<UserHomeDemoScreen />} />
                  <Route path="exam" element={<ExamDemoScreen />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
          </SentryRoutes>
          </div>
        </BrowserRouter>
       
        <ErrorModal show={errorModalDetails.show} title={errorModalDetails.title} text={errorModalDetails.text} handleClose={handleCloseErrorModal} />
      </div>
    </AppAuth.Provider>
  );
}

export default App;
