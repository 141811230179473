import React, {useEffect, useState, useContext, useRef} from "react"
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import EndExamModal from "./EndExamModal";
import { useNavigate } from 'react-router-dom';
import { remoteLog } from "../../utils/logging";

import "./Navigation.css";

export default function Navigation (props) {

    const expiryTime = new Date();
    let navigate = useNavigate();

    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const { t } = useTranslation();
    const [showEndExamModal, setShowEndExamModal] = useState(false);
    const [timeoutInterval, setTimeoutInterval ] = useState(null);
    
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTime, onExpire: () => {confirmCloseExam()} });

    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);
    const consumed_time_ref = useRef(props.consumed_time)
    const timeout_ref = useRef(props.timeout)
    const time_variation_ref = useRef(props.time_variation)

    const handleUpdateTime = async () => {
        const new_consumed_time = parseInt(timeout_ref.current + time_variation_ref.current) - parseInt(hours_ref.current*60 + minutes_ref.current + parseInt(seconds_ref.current/60));
        remoteLog(userAuthToken, 'info', `updated consumed time to ${new_consumed_time} minutes`)
        let response = await CallServer.update_time(userAuthToken, new_consumed_time);
        if (response.success){
            let data_to_update_local = {
                consumed_time: new_consumed_time,
                show_calculator: response.data.show_calculator,
                firstname: response.data.firstname,
                lastname: response.data.lastname
            }
            consumed_time_ref.current = new_consumed_time;
            if(parseInt(response.data.time_variation) !== parseInt(time_variation_ref.current)){
                data_to_update_local.time_variation = parseInt(response.data.time_variation)
                time_variation_ref.current = response.data.time_variation;
                remoteLog(userAuthToken, 'info', `updated time variation to ${response.data.time_variation} minutes`)
            }
            props.updateStaticExamData(data_to_update_local)
        } else {
            console.warn(response.error)
        }
    }

    const changeQuestion = (index) => {
        props.handleChangeQuestion(index);
    }

    const countConfirmed = () => {
        var count_confirmed = 0;
        props.questions.forEach((question) => {
            if (question.question_type === "base")
                count_confirmed = question.selected_answer_number !== null && !isNaN(question.selected_answer_number) ? count_confirmed + 1 : count_confirmed;
            else if (question.question_type === "multi")
                count_confirmed = question.selected_answer_numbers && question.selected_answer_numbers.length > 0 ? count_confirmed + 1 : count_confirmed;
            else if (question.question_type === "block") {
                var has_answer = false;
                question.blocks.forEach((block) => {
                    if (block.selected_answer_number !== null && block.selected_answer_number !== undefined && block.selected_answer_number !== NaN)
                        has_answer = true;
                })
                count_confirmed = has_answer ? count_confirmed + 1 : count_confirmed
            } else if (question.question_type === "text") {
                count_confirmed = (question.chars_count && question.chars_count > 0) || (question.given_answer_text && question.given_answer_text.length > 0 ) ? count_confirmed + 1 : count_confirmed;
            }
        })
        return count_confirmed;
    }

    const askEndExam = () => {
        if(isRunning)
            setShowEndExamModal(true);
    }
    const handleCloseModal = () => {
        setShowEndExamModal(false);
    }
    const confirmCloseExam = async () => {
        let response = await CallServer.close_exam(userAuthToken)
        if(response.success){
            console.log(timeoutInterval);
            remoteLog(userAuthToken, 'info', `ended exam`)
            navigate("/exam/examClosed", { replace: true, state: {exam_data: props.examData, id_interval: timeoutInterval}});
        } else    
            console.log(response.error)
    }

    const renderColor = () => {
        let perc = (parseInt(hours_ref.current * 60 + minutes_ref.current + Math.round(seconds_ref.current / 60)) * 100) / (timeout_ref.current+time_variation_ref.current);
        if (perc > 40)
            return ""
        else if (perc > 20)
            return "warning"
        else
            return "danger"
    }

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])

    useEffect(()=>{
        if(timeoutInterval)
            clearInterval(timeoutInterval)
        let time = new Date();
        time.setMinutes(time.getMinutes() + props.timeout + time_variation_ref.current - consumed_time_ref.current); 
        restart(time);
        let id_interval = setInterval(handleUpdateTime, 60000);
        setTimeoutInterval(id_interval)
        
    },[time_variation_ref.current, timeout_ref.current])

    

    return (
        <div className="col-3 navigation_bar pb-5">
            <div className={"exam_timer " + "text-"+renderColor()}>
                <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
            </div>
            <ProgressBar animated variant={renderColor()} now={((hours_ref.current * 60 + minutes_ref.current + (seconds_ref.current / 60))*100)/(timeout_ref.current+time_variation_ref.current)} />
            <div className="mt-2">
                <p className="text-secondary"> {t('question')} {parseInt(props.currentQuestion) + 1} {t('of')} {props.questions.length} ({t('confirmed_answer')}: {countConfirmed()} )</p>
            </div>
            <button className="mt-4" onClick={askEndExam}>
                {t('end_exam')}
            </button>
            <div className="mt-4 questions_nav">
                {
                    props.questions.map((question) => {
                        let has_answer = false;
                        if (question.question_type === "base")
                            has_answer = question.selected_answer_number !== null && !isNaN(question.selected_answer_number) ? true : false;
                        else if (question.question_type === "multi")
                            has_answer = question.selected_answer_numbers && question.selected_answer_numbers.length > 0 ? true : false;
                        else if (question.question_type === "block"){
                            question.blocks.forEach((block)=> {
                                if (block.selected_answer_number !== null) 
                                    has_answer = true;
                            })
                        } else if (question.question_type === "text"){
                            has_answer = (question.chars_count && question.chars_count > 0) || (question.given_answer_text && question.given_answer_text.length > 0) ? true : false;
                        }
                        return (
                            <div key={question.question_number} onClick={() => {changeQuestion(question.question_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.question_number === props.currentQuestion ? "current" : "")}>
                                {parseInt(question.question_number)+1}
                            </div>
                        )
                    })
                }
            </div>
            <EndExamModal show={showEndExamModal} isOnFinalScreen={false} remaining={{hours: hours, minutes: minutes, seconds: seconds}} handleConfirm={confirmCloseExam} handleClose={handleCloseModal} />
        </div>
    )
}