import { useEffect, useState } from "react"
import "./Question.css";
import { useTranslation } from 'react-i18next';
import { Form, OverlayTrigger, Popover, FormCheck } from "react-bootstrap";

import info_img from '../../assets/icons/info.svg'
import ok_icon from '../../assets/icons/ok_icon.svg'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function Question(props){

    const { t } = useTranslation();
    const [HasAnswer,setHasAnswer] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    const handleAnswerBaseQuestion = (event) =>{
        const target = event.target;
        const value = target.type === 'checkbox'  ? target.checked : target.value;
        const name = target.name;
        props.handleAnswerQuestion("base", props.data.question_number, value);
    }
    const handleAnswerMultiQuestion = (event) => {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;
        props.handleAnswerQuestion("multi", props.data.question_number, value, checked);
    }
    const handleAnswerBlockQuestion = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const block = target.dataset.block;
        props.handleAnswerQuestion("block", props.data.question_number, value, true, block);
    }
    const handleAnswerTextQuestion = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        props.handleAnswerQuestionText(props.data.question_number, value);
        
    }
    const confirmTextualAnswer = () => {
        console.log("Save")
        props.handleAnswerQuestionText(props.data.question_number, props.data.given_answer_text, true);
    }
    const resetAnswer = () => {
        if(props.data.question_type === "base")
            props.handleAnswerQuestion("base", props.data.question_number, null);
        else if (props.data.question_type === "block")
            props.handleResetBlockQuestion(props.data.question_number)
    }

    const renderAnswerType = (param) => {
        switch (param) {
            case 'base':
                return t("single_answer");
            case 'multi':
                return t("multiple_answer");
            case 'block':
                return t("block_answer");
            case 'text':
                return t("text_answer");
            default:
                return '';
        }
    }

    const handlePreventCopyPaste = (e) => {
        e.preventDefault();
        window.alert(t("disabled_copy_paste"))
    }


    useEffect(()=>{
        let has_answer = false;
        if (props.data.question_type === "block")
            props.data.blocks.forEach((block) => {
                if (block.selected_answer_number !== null)
                    has_answer = true
            })
        else if (props.data.question_type === "base")
            if (props.data.selected_answer_number !== null)
                has_answer = true
        setHasAnswer(has_answer)
    },[props])

    useEffect(()=>{
        setImageLoaded(false)
    },[props?.data?.image_url])

    return (
        <div className="col-9 exam_question_container px-4 pb-5">
            {
                props.data? 
                    <>
                        <p className="text-black fw-bold">{t('question')} {parseInt(props.currentQuestion) + 1} {props.data.subject_name ? <span className="text-secondary"> - ({props.data.subject_name})</span> : ""}</p>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: props.data.text }} />
                        { props.data.image_url ?
                            <> 
                                <Zoom>
                                    <img src={props.data.image_url} alt="Question Image" className="mt-3" style={{width:"100%", visibility: imageLoaded ? 'visible' : 'hidden'}} onLoad={()=>{setImageLoaded(true)}} />
                                    {
                                        !imageLoaded ?
                                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        : ""
                                    }
                                </Zoom>
                                
                                <small className="d-block text-secondary">{t("click_on_image")}</small>
                            </>
                        : ""}
                        {
                            props.data.additional_material_instructions ? <p className="mt-2 text-secondary">{props.data.additional_material_instructions}</p> : ""
                        }
                        <div className="d-flex mt-3 align-items-center justify-content-between">
                            <p className="text-black  fw-bold">
                                {renderAnswerType(props.data.question_type)} 
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement={"top"}
                                    overlay={
                                        <Popover id={`popover-scoring`}>
                                            <Popover.Body>
                                                <strong>{t('score_title')}</strong>
                                                {props.data.question_type !== "text" ? 
                                                    <ul>
                                                        <li>{t('correct_answer')}: {props.data.scoring.correct_answer} {t('points')} </li>  
                                                        <li>{t('missing_answer')}: {props.data.scoring.missing_answer} {t('points')}</li> 
                                                        <li>{t('wrong_answer')}:  {props.data.scoring.wrong_answer} {t('points')}</li> 
                                                        {props.data.scoring.blocking_score != null ? 
                                                            <li>{t('blocking_score')}:  {props.data.scoring.blocking_score} {t('points')}</li> 
                                                        : ""}
                                                        {props.data.scoring.allow_partial_answer ?
                                                            <li>{t('partial_answer')}</li>
                                                            : ""}
                                                    </ul>
                                                :
                                                    <ul>
                                                        {props.data.scoring.words_limit != null ?
                                                            <li>{t('words_limit')}: {props.data.scoring.words_limit} </li>
                                                            : ""}
                                                        {props.data.scoring.chars_limit != null ?
                                                            <li>{t('chars_limit')}: {props.data.scoring.chars_limit} </li>
                                                            : ""}
                                                    </ul>   
                                                    }
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <img src={info_img} className="ms-2 pe-auto information_image_dot" style={{cursor:"pointer"}} alt="Info"/>
                                </OverlayTrigger>
                            </p>
                            {
                                ( HasAnswer ) && props.data.question_type !== "multi" ? 
                                    <span className="reset_answer" onClick={resetAnswer}>{t('reset_answer')}</span>
                                : ""
                            }
                        </div>
                        {
                            props.data.question_type === "base" ?
                                <div className="answers_list">
                                    {props.data.answers.map((answer) => {
                                        return(
                                            <label className={"answer " + ( answer.answer_number == props.data.selected_answer_number ? "selected" : "" )} key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                
                                                <FormCheck>
                                                    <FormCheck.Input  
                                                        type={"radio"}
                                                        name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        value={answer.answer_number} 
                                                        onChange={handleAnswerBaseQuestion}
                                                        checked={answer.answer_number == props.data.selected_answer_number ? true : false}
                                                    />
                                                    <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} dangerouslySetInnerHTML={{ __html: answer.text }} />
                                                </FormCheck>
                                            </label>
                                        )
                                    })}
                                </div>
                            : ""
                        }
                        {
                            props.data.question_type === "multi" ?
                                <div className="answers_list">
                                    {props.data.answers.map((answer) => {
                                        return (
                                            <label className={"answer " + (props.data.selected_answer_numbers && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? "selected" : "")}  key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                
                                                <FormCheck>
                                                    <FormCheck.Input  
                                                        type={"checkbox"}
                                                        name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        value={answer.answer_number} 
                                                        onChange={handleAnswerMultiQuestion}
                                                        checked={props.data.selected_answer_numbers !== null && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? true : false}
                                                />
                                                    <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} dangerouslySetInnerHTML={{ __html: answer.text }} />
                                                </FormCheck>
                                            </label>
                                        )
                                    })}
                                </div>
                                : ""
                        }
                        {
                            props.data.question_type === "block" ?
                                <div className="answers_list">
                                    {props.data.blocks.map((block) => {
                                        return (
                                            <div className="block" key={block.block_number} >
                                                <p dangerouslySetInnerHTML={{ __html: block.text }} />
                                                <div className="d-flex">
                                                    {block.answers.map((answer) => {
                                                        return (
                                                            <div className="px-2 w-100" key={answer.answer_number}>
                                                                <label className={"answer " + (block.selected_answer_number !== null && block.selected_answer_number == answer.answer_number ? "selected" : "")} key={answer.answer_number} htmlFor={`answer-block-${props.data.question_number}-${block.block_number}-${answer.answer_number}`}>
                                                                    <Form.Check
                                                                        type={"radio"}
                                                                        name={`answer-block-${props.data.question_number}-${block.block_number}-${answer.answer_number}`}
                                                                        id={`answer-block-${props.data.question_number}-${block.block_number}-${answer.answer_number}`}
                                                                        label={answer.text}
                                                                        value={answer.answer_number}
                                                                        data-block={block.block_number}
                                                                        onChange={handleAnswerBlockQuestion}
                                                                        checked={block.selected_answer_number !== null && block.selected_answer_number == answer.answer_number ? true : false}
                                                                    />
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                : ""
                        }
                        {
                            props.data.question_type === "text" ?
                                <div className="answers_list">
                                    <div className="text-right text_answer_limit">
                                        {props.data.scoring.words_limit != null ?
                                            <span><strong>{props.data.words_count ? props.data.words_count : (props.data.given_answer_text ? (props.data.given_answer_text.split(' ').filter(Boolean)).length : 0 )}</strong>/{props.data.scoring.words_limit} {t('words')} </span>
                                            : ""}
                                        {props.data.scoring.chars_limit != null ?
                                            <span className="ms-2"><strong>{props.data.chars_count ? props.data.chars_count : (props.data.given_answer_text ? props.data.given_answer_text.length : 0)} </strong>/{props.data.scoring.chars_limit} {t('chars')} </span>
                                            : ""}
                                    </div>
                                    <div className="textarea_box">
                                      <textarea 
                                        className={"textarea_text_question " + ( props.data.is_over_limit ? "over_limit_text_answer" : "")}
                                        onChange={handleAnswerTextQuestion}
                                        value={props.data.given_answer_text ? props.data.given_answer_text : "" }
                                            onPaste={handlePreventCopyPaste} 
                                            onCopy={handlePreventCopyPaste}
                                        />
                                    </div>
                                    <div className={`d-flex mt-2 ${props.data.saved_answer ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
                                        {
                                            props.data.saved_answer ? 
                                             <p className="text-success">{t("saved_answer")}</p>
                                            : ""
                                        }
                                        {!props.is_linear_exam ?
                                            <button onClick={confirmTextualAnswer} disabled={props.data.saved_answer} className={props.data.saved_answer ? "disable_saved" : ""}>
                                                {t("save_textual_answer")}
                                            </button>
                                        : ""}
                                    </div>
                                </div>
                                : ""
                        }
                    </>
                : ""
            }
        </div>
    )
}