export const FakeExamData = {
    "exam": {
        "title": "Esame DEMO",
        "subtitle": "",
        "instructions": "<p>Istruzioni</p>",
        "date": new Date(),
        "timeout": 45,
        "questions_count": 13,
        "allow_navigation": true,
        "start_date": null,
        "end_date": null
    },
    "start_date": null,
    "end_date": null,
    "consumed_time": null,
    "show_calculator": false,
    "time_variation": 0,
    "questions": [
        {
            "text": "<p>Qual &egrave; la capitale del Portogallo?</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 0,
            "question_type": "base",
            "answers": [
                {
                    "text": "Lisbona",
                    "answer_number": 0
                },
                {
                    "text": "Coimbra",
                    "answer_number": 1
                },
                {
                    "text": "Aveiro",
                    "answer_number": 2
                },
                {
                    "text": "Faro",
                    "answer_number": 3
                },
                {
                    "text": "Porto",
                    "answer_number": 4
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>Elenca cinque capoluoghi di provincia piemontesi.</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 1,
            "question_type": "text",
            "given_answer_text": null,
            "scoring": {
                "chars_limit": 1000,
                "words_limit": null
            }
        },
        {
            "text": "<p>Il film \"I 400 colpi\" &egrave; ambientato a:</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 2,
            "question_type": "base",
            "answers": [
                {
                    "text": "Londra",
                    "answer_number": 0
                },
                {
                    "text": "Roma",
                    "answer_number": 1
                },
                {
                    "text": "Parigi",
                    "answer_number": 2
                },
                {
                    "text": "Madrid",
                    "answer_number": 3
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>Quali delle seguenti citt&agrave; si trovano in Scandinavia?</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 3,
            "question_type": "multi",
            "answers": [
                {
                    "text": "Amburgo",
                    "answer_number": 0
                },
                {
                    "text": "Bergen",
                    "answer_number": 1
                },
                {
                    "text": "Leeds",
                    "answer_number": 2
                },
                {
                    "text": "Oslo",
                    "answer_number": 3
                },
                {
                    "text": "Stoccolma",
                    "answer_number": 4
                }
            ],
            "selected_answer_numbers": [],
            "scoring": {
                "correct_answer": 1,
                "missing_answer": 0,
                "wrong_answer": -0.5,
                "allow_partial_answer": true,
                "forgive_fair_wrong_answer": false
            }
        },
        {
            "text": "<p>Milano</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 4,
            "question_type": "block",
            "blocks": [
                {
                    "text": "ospiterà le Olimpiadi invernali 2026.",
                    "answers": [
                        {
                            "text": "True",
                            "answer_number": 0
                        },
                        {
                            "text": "False",
                            "answer_number": 1
                        }
                    ],
                    "selected_answer_number": null,
                    "block_number": 0
                },
                {
                    "text": "ha dato i natali a Dino Buzzati.",
                    "answers": [
                        {
                            "text": "True",
                            "answer_number": 0
                        },
                        {
                            "text": "False",
                            "answer_number": 1
                        }
                    ],
                    "selected_answer_number": null,
                    "block_number": 1
                },
                {
                    "text": "è la città più popolosa d'Italia.",
                    "answers": [
                        {
                            "text": "True",
                            "answer_number": 0
                        },
                        {
                            "text": "False",
                            "answer_number": 1
                        }
                    ],
                    "selected_answer_number": null,
                    "block_number": 2
                },
                {
                    "text": "è il titolo di una canzone di Lucio Dalla.",
                    "answers": [
                        {
                            "text": "True",
                            "answer_number": 0
                        },
                        {
                            "text": "False",
                            "answer_number": 1
                        }
                    ],
                    "selected_answer_number": null,
                    "block_number": 3
                },
                {
                    "text": "si trova a nord di Bergamo.",
                    "answers": [
                        {
                            "text": "True",
                            "answer_number": 0
                        },
                        {
                            "text": "False",
                            "answer_number": 1
                        }
                    ],
                    "selected_answer_number": null,
                    "block_number": 4
                }
            ],
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": 0,
                "blocking_score": null
            }
        },
        {
            "text": "<p>La Galleria Nazionale d'Arte Moderna e Contemporanea si trova a:</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 5,
            "question_type": "base",
            "answers": [
                {
                    "text": "Milano",
                    "answer_number": 0
                },
                {
                    "text": "Genova",
                    "answer_number": 1
                },
                {
                    "text": "Roma",
                    "answer_number": 2
                },
                {
                    "text": "Torino",
                    "answer_number": 3
                },
                {
                    "text": "Firenze",
                    "answer_number": 4
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>La statua mostrata in figura si trova a:</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": "https://storage-unisr-appelli.it.thefacultyapp.com/0b529f31-b992-4a6d-a9c6-073d4d34bfaa/dito.jpg",
            "question_number": 6,
            "question_type": "base",
            "answers": [
                {
                    "text": "Manchester",
                    "answer_number": 0
                },
                {
                    "text": "Parigi",
                    "answer_number": 1
                },
                {
                    "text": "Berlino",
                    "answer_number": 2
                },
                {
                    "text": "Milano",
                    "answer_number": 3
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>Mario Draghi &egrave; originario di:</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 7,
            "question_type": "base",
            "answers": [
                {
                    "text": "Roma",
                    "answer_number": 0
                },
                {
                    "text": "Genova",
                    "answer_number": 1
                },
                {
                    "text": "Firenze",
                    "answer_number": 2
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>Stoccarda &egrave; una citt&agrave;:</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 8,
            "question_type": "base",
            "answers": [
                {
                    "text": "francese",
                    "answer_number": 0
                },
                {
                    "text": "tedesca",
                    "answer_number": 1
                },
                {
                    "text": "belga",
                    "answer_number": 2
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>Quali delle seguenti citt&agrave; sono individuate dalla legge come Citt&agrave; Metropolitane?</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 9,
            "question_type": "multi",
            "answers": [
                {
                    "text": "Torino",
                    "answer_number": 0
                },
                {
                    "text": "Milano",
                    "answer_number": 1
                },
                {
                    "text": "Ancona",
                    "answer_number": 2
                },
                {
                    "text": "Ferrara",
                    "answer_number": 3
                }
            ],
            "selected_answer_numbers": [],
            "scoring": {
                "correct_answer": 1,
                "missing_answer": 0,
                "wrong_answer": -0.25,
                "allow_partial_answer": true,
                "forgive_fair_wrong_answer": false
            }
        },
        {
            "text": "<p>Elenca dieci capoluoghi di Regione italiani.</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 10,
            "question_type": "text",
            "given_answer_text": null,
            "scoring": {
                "chars_limit": null,
                "words_limit": 2000
            }
        },
        {
            "text": "<p>Esch-sur-Alzette, Capitale europea della cultura 2022, si trova in:</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 11,
            "question_type": "base",
            "answers": [
                {
                    "text": "Olanda",
                    "answer_number": 0
                },
                {
                    "text": "Francia",
                    "answer_number": 1
                },
                {
                    "text": "Lussemburgo",
                    "answer_number": 2
                },
                {
                    "text": "Germania",
                    "answer_number": 3
                },
                {
                    "text": "Belgio",
                    "answer_number": 4
                }
            ],
            "selected_answer_number": null,
            "scoring": {
                "correct_answer": 2,
                "missing_answer": 0,
                "wrong_answer": -0.5
            }
        },
        {
            "text": "<p>Quali delle seguenti citt&agrave; sono capitali europee?</p>",
            "additional_material_instructions": null,
            "subject_name": null,
            "image_url": null,
            "question_number": 12,
            "question_type": "multi",
            "answers": [
                {
                    "text": "Anversa",
                    "answer_number": 0
                },
                {
                    "text": "Zagabria",
                    "answer_number": 1
                },
                {
                    "text": "Stoccarda",
                    "answer_number": 2
                },
                {
                    "text": "Varsavia",
                    "answer_number": 3
                }
            ],
            "selected_answer_numbers": [],
            "scoring": {
                "correct_answer": 1,
                "missing_answer": 0,
                "wrong_answer": -0.5,
                "allow_partial_answer": true,
                "forgive_fair_wrong_answer": false
            }
        }
    ]
}