import React, { useEffect, useState, useContext, useRef } from "react"
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import CallServer from '../../api/apis'
import { AppAuth } from "../../utils/Auth";
import EndExamModal from "./EndExamModal";
import { useNavigate } from 'react-router-dom';
import { remoteLog } from "../../utils/logging";

import "./Navigation.css";

export default function NavigationLinear(props) {

    const expiryTime = new Date();
    let navigate = useNavigate();

    const { userAuthToken, setUserAuthToken } = useContext(AppAuth);
    const { t } = useTranslation();
    const [showEndExamModal, setShowEndExamModal] = useState(false);
    const [timeoutInterval, setTimeoutInterval] = useState(null);

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTime, onExpire: () => {confirmCloseExam()} });

    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);
    const consumed_time_ref = useRef(props.consumed_time)
    const timeout_ref = useRef(props.timeout)
    const time_variation_ref = useRef(props.time_variation)

    const handleUpdateTime = async () => {
        const new_consumed_time = parseInt(timeout_ref.current + time_variation_ref.current) - parseInt(hours_ref.current*60 + minutes_ref.current + parseInt(seconds_ref.current/60));
        remoteLog(userAuthToken, 'info', `updated consumed time to ${new_consumed_time} minutes`)
        let response = await CallServer.update_time(userAuthToken, new_consumed_time);
        if (response.success){
            let data_to_update_local = {
                consumed_time: new_consumed_time,
                show_calculator: response.data.show_calculator,
                firstname: response.data.firstname,
                lastname: response.data.lastname
            }
            consumed_time_ref.current = new_consumed_time;
            if(parseInt(response.data.time_variation) !== parseInt(time_variation_ref.current)){
                data_to_update_local.time_variation = parseInt(response.data.time_variation)
                time_variation_ref.current = response.data.time_variation;
                remoteLog(userAuthToken, 'info', `updated time variation to ${response.data.time_variation} minutes`)
            }
            console.log(data_to_update_local)
            props.updateStaticExamData(data_to_update_local)
        } else {
            console.log(response.error)
        }
    }

    const range = (start, end) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const askEndExam = () => {
        if (isRunning)
            setShowEndExamModal(true);
    }
    const handleCloseModal = () => {
        setShowEndExamModal(false);
    }
    const confirmCloseExam = () => {
        props.handleCloseExam(timeoutInterval);
    }

    const renderColor = () => {
        let perc = (parseInt(hours_ref.current * 60 + minutes_ref.current + Math.round(seconds_ref.current / 60)) * 100) / (timeout_ref.current+time_variation_ref.current);
        if(perc > 40)
            return ""
        else if (perc > 20)
            return "warning"
        else
            return "danger"
    }

    const [questionList, setQuestionList] = useState([]);

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    useEffect(() => {
        setQuestionList(range(1, props.questionsCount))
    }, [props.questionsCount])

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])


    useEffect(()=>{
        if(timeoutInterval)
            clearInterval(timeoutInterval)
        let time = new Date();
        time.setMinutes(time.getMinutes() + props.timeout + time_variation_ref.current - consumed_time_ref.current); 
        restart(time);
        let id_interval = setInterval(handleUpdateTime, 60000);
        setTimeoutInterval(id_interval)
    },[time_variation_ref.current, timeout_ref.current])

    return (
        <div className="col-3 navigation_bar pb-5">
            <div className={"exam_timer " + "text-" + renderColor()}>
                <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
            </div>
            <ProgressBar animated variant={renderColor()} now={((hours_ref.current * 60 + minutes_ref.current + (seconds_ref.current / 60))*100)/(timeout_ref.current+time_variation_ref.current)} />
            <div className="mt-2">
                <p className="text-secondary"> {t('question')} {parseInt(props.currentQuestion) +1 } {t('of')} {props.questionsCount} ({t('confirmed_answer')}: {props.examData.current_question ? props.currentQuestion : props.questionsCount} )</p>
            </div>
            <button className="mt-4" onClick={askEndExam}>
                {t('end_exam')}
            </button>
            <div className="mt-4 questions_nav">
                {
                    questionList.map((question) => {
                        return (
                            <div key={question} style={{cursor:"unset"}}  className={"question_el " + (question <= props.currentQuestion || !props.examData.current_question ? "answered " : "") + (question-1 == props.currentQuestion ? "current" : "")}>
                                {parseInt(question)}
                            </div>
                        )
                    })
                }
            </div>
            <EndExamModal show={showEndExamModal} isOnFinalScreen={props.examData.current_question ? false : true} remaining={{ hours: hours, minutes: minutes, seconds: seconds }} handleConfirm={confirmCloseExam} handleClose={handleCloseModal} />
        </div>
    )
}