import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './config/i18nextConfig';
import {  useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
  dsn: "https://a653df05c2e2492495e6adb712e0eded@o1240163.ingest.sentry.io/4504791779573765",
  integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
  }), new Sentry.Replay()],
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SESSION_RATE),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.3,
});

ReactDOM.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
